/* eslint-disable max-lines-per-function */
import { useState } from 'react'
import { logger } from '../../utils/logger'

function useWorkspaceModal({
  user,
  workspaceModalTabIndex,
  createWorkspace,
  onCreateWorkspaceDone,
  onWorkspaceModalClose,
  inviteToWorkspace,
  setActiveWorkspaceId
}) {
  const [hasSentInvitation, setHasSentInvitation] = useState(false)

  const [isSecondStepLoading, setIsSecondStepLoading] = useState(false)
  const [workspaceName, setWorkspaceName] = useState('')
  const [isRestricted, setIsRestricted] = useState(false)
  const [workspaceCreationId, setWorkspaceCreationId] = useState(null)
  const [step, setStep] = useState(workspaceModalTabIndex || 1)

  const workspaceId = workspaceCreationId || user.defaultWorkspaceId
  const activeStep = workspaceModalTabIndex || step

  const onCreateWorkspaceSubmit = async ({ name, visibility }) => {
    const result = await createWorkspace({ name, visibility })
    if (result) {
      setStep(2)
      setWorkspaceName(name)
      setIsRestricted(visibility === 'restricted')
      setWorkspaceCreationId(result._id)
      setActiveWorkspaceId(result._id)
    }
    if (onCreateWorkspaceDone) {
      onCreateWorkspaceDone()
    }
  }

  const onCollaborateOnWorkspaceSubmit = async (data) => {
    try {
      setIsSecondStepLoading(true)
      const { emails, message } = data
      await inviteToWorkspace({ workspaceId, emails, message })
      setHasSentInvitation(true)
    } catch (error) {
      logger.error(error)
      throw error
    } finally {
      setIsSecondStepLoading(false)
    }
  }

  const onCloseCollaborateModal = async () => {
    await onWorkspaceModalClose(workspaceId)
    setStep(1)
  }

  return {
    workspaceName,
    isRestricted,
    workspaceCreationId,
    step,
    activeStep,
    isSecondStepLoading,
    hasSentInvitation,
    setStep,
    setWorkspaceName,
    setIsRestricted,
    setWorkspaceCreationId,
    onCreateWorkspaceSubmit,
    onCloseCollaborateModal,
    onCollaborateOnWorkspaceSubmit,
    setHasSentInvitation
  }
}

export default useWorkspaceModal

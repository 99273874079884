import { Suspense, lazy } from 'react'
import { IntercomProvider } from 'react-use-intercom'
import * as Sentry from '@sentry/react'
import { Auth0Provider } from '@auth0/auth0-react'
import { HelmetProvider } from 'react-helmet-async'
import AuthProvider from './context/AuthProvider'
import TopBannerNotificationProvider from './context/TopBannerNotificationProvider'
import { ERROR_MESSAGES, INTERCOM_APP_ID, AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './utils/constants'
import PageLoader from './components/helpers/PageLoader'
import RoutesComponents from './routes'
import OrganizationProvider from './context/OrganizationProvider'
import PersonaProvider from './context/PersonaProvider'
import JourneyProvider from './context/JourneyProvider'
import OpportunityProvider from './context/OpportunityProvider'
import ProblemProvider from './context/ProblemProvider'
import IssueProvider from './context/IssueProvider'
import WorkspaceProvider from './context/WorkspaceProvider'
import AlertProvider from './context/AlertProvider'
import FeatureFlagProvider from './context/FeatureFlagProvider'
import NotificationProvider from './context/NotificationProvider'
import CommentProvider from './context/CommentProvider'
import ErrorCollectorProvider from './context/ErrorCollectorProvider'

const Loadable = (Component) => (properties) => (
  <Suspense fallback={<PageLoader />}>
    <Component {...properties} />
  </Suspense>
)

const Error = Loadable(lazy(() => import('./pages/errors/ErrorView')))

export default function App() {
  const urlParameters = new URLSearchParams(window.location.search)
  const invitation = urlParameters.get('invitation')
  const organization = urlParameters.get('organization')

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access',
        ...(invitation ? { invitation } : {}),
        ...(organization ? { organization } : {})
      }}
      useRefreshTokens
    >
      <HelmetProvider>
        <AlertProvider>
          <Sentry.ErrorBoundary fallback={<Error errorData={ERROR_MESSAGES.SERVICE_UNAVAILABLE} />}>
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <TopBannerNotificationProvider>
                <AuthProvider>
                  <FeatureFlagProvider>
                    <NotificationProvider>
                      <OrganizationProvider>
                        <ErrorCollectorProvider>
                          <PersonaProvider>
                            <JourneyProvider>
                              <OpportunityProvider>
                                <ProblemProvider>
                                  <IssueProvider>
                                    <CommentProvider>
                                      <WorkspaceProvider>
                                        <RoutesComponents />
                                      </WorkspaceProvider>
                                    </CommentProvider>
                                  </IssueProvider>
                                </ProblemProvider>
                              </OpportunityProvider>
                            </JourneyProvider>
                          </PersonaProvider>
                        </ErrorCollectorProvider>
                      </OrganizationProvider>
                    </NotificationProvider>
                  </FeatureFlagProvider>
                </AuthProvider>
              </TopBannerNotificationProvider>
            </IntercomProvider>
          </Sentry.ErrorBoundary>
        </AlertProvider>
      </HelmetProvider>
    </Auth0Provider>
  )
}

import { Routes, Route } from 'react-router-dom'
import React, { Suspense, lazy } from 'react'
import PageLoader from './components/helpers/PageLoader'
import Router from './utils/history-router'
import ScrollToTop from './components/helpers/ScrollToTop'
import DashboardSidebarLayout from './components/layouts/DashboardSidebarLayout'
import CheckIfComingFromResetPassword from './components/helpers/CheckIfComingFromResetPassword.js'
import { ERROR_MESSAGES } from './utils/constants.js'

const Loadable = (Component) => (properties) => (
  <Suspense fallback={<PageLoader />}>
    <Component {...properties} />
  </Suspense>
)

const Index = Loadable(lazy(() => import('./pages/Index')))
const Dashboard = Loadable(lazy(() => import('./pages/home/Home')))
const Logout = Loadable(lazy(() => import('./pages/auth/Logout')))
const Privacy = Loadable(lazy(() => import('./pages/static/Privacy')))
const Terms = Loadable(lazy(() => import('./pages/static/Terms')))
const Personas = Loadable(lazy(() => import('./pages/personas/Personas')))
const EditPersona = Loadable(lazy(() => import('./pages/personas/EditPersona')))
const ReadOnlyPersona = Loadable(lazy(() => import('./pages/personas/ReadOnlyPersona')))
const CreatePersona = Loadable(lazy(() => import('./pages/personas/CreatePersona')))
const Journeys = Loadable(lazy(() => import('./pages/journeys/Journeys')))
const CreateJourney = Loadable(lazy(() => import('./pages/journeys/CreateJourney')))
const JourneyDetailWithWebsocket = Loadable(lazy(() => import('./pages/journeys/JourneyDetailWithWebsocket.js')))
const Onboarding = Loadable(lazy(() => import('./pages/auth/Onboarding')))
const OnboardingOrganisation = Loadable(lazy(() => import('./pages/auth/OnboardingOrganisation')))
const Error = Loadable(lazy(() => import('./pages/errors/ErrorView.js')))
const ImprovementsBoard = Loadable(lazy(() => import('./pages/improvements/ImprovementsBoard')))
const CreateBoard = Loadable(lazy(() => import('./pages/improvements/CreateBoard.js')))
const IssueLibrary = Loadable(lazy(() => import('./pages/improvements/IssueLibrary')))

const RoutesComponent = () => {
  return (
    <Router>
      <ScrollToTop />
      <CheckIfComingFromResetPassword />
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/account/setup" element={<Onboarding />} />
        <Route path="/account/organization" element={<OnboardingOrganisation />} />
        <Route path="/" element={<DashboardSidebarLayout />}>
          <Route path="" element={<Index />} index />
          <Route path="home" element={<Dashboard />} />
          <Route path="maps" element={<Journeys />} />
          <Route path="maps/:journeyId/edit" element={<JourneyDetailWithWebsocket />} />
          <Route path="maps/new/start" element={<CreateJourney />} />
          <Route path="personas" element={<Personas />} />

          {/* These routes will be deprecated later in favour of /tasks routes */}
          <Route path="improvements" element={<IssueLibrary />} />
          <Route path="improvements/board" element={<ImprovementsBoard />} />
          <Route path="improvements/details/:name" element={<ImprovementsBoard />} />
          <Route path="improvements/new/board" element={<CreateBoard />} />

          {/* Routes for journey specific tasks & board */}
          <Route path="maps/:journeyId/tasks" element={<IssueLibrary />} />
          <Route path="maps/:journeyId/board" element={<ImprovementsBoard />} />

          <Route path="tasks" element={<IssueLibrary />} />
          <Route path="tasks/board" element={<ImprovementsBoard />} />
          <Route path="tasks/details/:name" element={<ImprovementsBoard />} />
          <Route path="tasks/new/board" element={<CreateBoard />} />
        </Route>
        <Route path="/session-timeout" element={<Error errorData={ERROR_MESSAGES.SESSION_TIMEOUT} />} />
        <Route path="*" element={<Error errorData={ERROR_MESSAGES.NOT_FOUND} />} />
      </Routes>
    </Router>
  )
}

export default RoutesComponent

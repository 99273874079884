import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { API_SERVICES, AUTH0_AUDIENCE, CONFIG } from '../../utils/constants'
import sanitizeObject from '../../utils/sanitizeObject'
import { useAuth } from '../../context/AuthProvider'
import useGetToken from '../useGetToken'

// eslint-disable-next-line max-lines-per-function
const useWorkspace = (options = {}) => {
  const { defaultWorkspaceList = [] } = options
  const getToken = useGetToken()
  const { user } = useAuth()
  const [result, setResult] = useState(defaultWorkspaceList)
  const [isLoading, setIsLoading] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [members, setMembers] = useState([])
  const { getAccessTokenSilently } = useAuth0()

  // eslint-disable-next-line max-lines-per-function
  const createWorkspace = async ({ name, visibility }) => {
    try {
      setErrorMessage(null)
      setIsLoading(true)
      const token = await getAccessTokenSilently({
        cacheMode: 'off',
        authorizationParams: { audience: AUTH0_AUDIENCE }
      })
      if (!token) {
        throw new Error("Can't get token from auth0")
      }
      const organizationId = user?.defaultOrg
      const data = {
        name,
        visibility,
        organizationId
      }
      const sanitizedPayload = sanitizeObject(data)
      const response = await fetch(
        `${CONFIG.BACKEND_BASE_URL}/${API_SERVICES.WORKSPACE}?organizationId=${organizationId}&disableCache=true`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(sanitizedPayload)
        }
      )

      if (response.status === 409) {
        throw new Error('Workspace already exists within the organization')
      }

      const result = await response.json()
      if (!response.ok) {
        throw new Error(result.message)
      }

      // Refresh new auth0 token
      await getAccessTokenSilently({
        cacheMode: 'off',
        authorizationParams: { audience: AUTH0_AUDIENCE }
      })

      return result
    } catch (error) {
      setErrorMessage(error.message)
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const syncWorkspaceName = async (id) => {
    try {
      setErrorMessage(null)
      const token = await getToken(true)
      if (!token) {
        throw new Error("Can't get token from auth0")
      }
      const displayName = await getWorkspaceName(id)
      const data = {
        displayName
      }
      const sanitizedPayload = sanitizeObject(data)
      const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/${API_SERVICES.WORKSPACE}/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(sanitizedPayload)
      })

      const result = await response.json()
      if (!response.ok) {
        throw new Error(result.message)
      }

      return result
    } catch (error) {
      setErrorMessage(error.message)
      return null
    }
  }

  const inviteToWorkspace = async ({ workspaceId, emails, message, role }) => {
    const token = await getToken(true)
    if (!token) {
      throw new Error("Can't get token from auth0")
    }
    const organizationId = user?.defaultOrg
    const data = {
      emails,
      message,
      role
    }
    const sanitizedPayload = sanitizeObject(data)
    const response = await fetch(
      `${CONFIG.BACKEND_BASE_URL}/${API_SERVICES.WORKSPACE}/${workspaceId}/invite?organizationId=${organizationId}&disableCache=true`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(sanitizedPayload)
      }
    )
    const result = await response.json()
    if (!response.ok) {
      throw new Error(result.message)
    }
    const errorResult = result?.find((res) => res.error)
    if (errorResult) {
      throw new Error(errorResult.message)
    }
    return result
  }

  const removeFromWorkspace = async ({ workspaceId, userId }) => {
    try {
      const token = await getToken(true)
      if (!token) {
        throw new Error("Can't get token from auth0")
      }
      setIsRemoving(true)
      const response = await fetch(
        `${CONFIG.BACKEND_BASE_URL}/${API_SERVICES.WORKSPACE}/${workspaceId}/members/${userId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      )
      const result = await response.json()
      if (!response.ok) {
        throw new Error(result.message)
      }
      if (result.error) {
        throw new Error(result.message)
      }
      setIsRemoving(false)
      return result
    } catch (error) {
      setIsRemoving(false)
      throw error
    }
  }

  const getAllWorkspace = async () => {
    try {
      setErrorMessage(null)
      const token = await getToken()
      if (!token) {
        throw new Error('API token does not exist')
      }

      const organizationId = user?.defaultOrg

      const queryObject = {
        organizationId: organizationId,
        workspaceId: user?.defaultWorkspaceId,
        disableCache: true
      }

      const queryString = Object.entries(queryObject)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')

      const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/${API_SERVICES.USER_WORKSPACES}?${queryString}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      setIsLoading(true)

      const allWorkspacesForThisUser = await response.json()

      if (allWorkspacesForThisUser.error) {
        throw new Error(allWorkspacesForThisUser.message)
      }

      const workspaces = allWorkspacesForThisUser.filter((workspace) => workspace.organizationId === organizationId)

      setResult(workspaces)

      return workspaces
    } catch (error) {
      setErrorMessage(error.message)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const extractInitials = (name) => {
    const [firstName, lastName] = name.split(' ')
    return `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toLowerCase()
  }

  const updatePictureUrl = (picture, initials) => {
    let decodedUrl = decodeURIComponent(picture).replace(/(\w+)(\.png)$/, `${initials}$2`)
    const urlParts = decodedUrl.split('?')
    if (urlParts.length > 1) {
      const queryParameters = urlParts[1]
        .split('&')
        .map((parameter) => parameter.split('=').map(encodeURIComponent).join('='))
        .join('&')
      decodedUrl = `${urlParts[0]}?${queryParameters}`
    }
    return decodedUrl
  }

  /**
   * Updates the picture URL of each member in the provided list by appending their initials to the filename part of the URL.
   *
   * @param {Array<Object>} members - An array of member objects to update.
   * @returns {Array<Object>} A new array of member objects with updated `picture` URLs.
   */
  const updateMembersWithInitialsInPictureUrl = (members = []) => {
    return members.map((member) => {
      const initials = extractInitials(member?.name)
      const updatedPictureUrl = updatePictureUrl(member.picture, initials)
      return {
        ...member,
        picture: updatedPictureUrl
      }
    })
  }

  const getAllMembersData = async ({ workspaceId, disableCache = true }) => {
    try {
      setErrorMessage(null)
      const token = await getToken()
      if (!token) {
        throw new Error('API token does not exist')
      }
      const organizationId = user?.defaultOrg

      const queryObject = {
        organizationId: organizationId,
        workspaceId: user?.defaultWorkspaceId,
        disableCache
      }

      const queryString = Object.entries(queryObject)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')

      const response = await fetch(
        `${CONFIG.BACKEND_BASE_URL}/${API_SERVICES.WORKSPACE}/${workspaceId}/members?${queryString}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      )
      setIsLoading(true)
      const result = await response.json()
      if (result.error) {
        throw new Error(result.message)
      }
      const members = updateMembersWithInitialsInPictureUrl(result)
      setMembers(members)
      return result
    } catch (error) {
      setErrorMessage(error.message)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const getWorkspaceName = (id) => {
    const workspace = result?.find((ws) => ws._id === id)
    return workspace?.displayName || workspace?.display_name
  }

  const changeWorkspaceName = (id, name) => {
    const newResult = result.map((workspace) => {
      if (workspace._id === id) {
        return {
          ...workspace,
          displayName: name
        }
      }
      return workspace
    })

    setResult(newResult)
  }

  return {
    getAllWorkspace,
    result,
    createWorkspace,
    isLoading,
    errorMessage,
    inviteToWorkspace,
    removeFromWorkspace,
    isRemoving,
    getAllMembersData,
    members,
    getWorkspaceName,
    changeWorkspaceName,
    syncWorkspaceName,
    updateMembersWithInitialsInPictureUrl
  }
}

export default useWorkspace

/* istanbul ignore file */

import classNames from 'classnames'
import { Bars3Icon, ChevronDoubleLeftIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import Logo from '../logo/Logo'
import ProfileDropdownMenu from './ProfileDropdownMenu'
import SidebarNavigationDesktop from './SidebarNavigationDesktop'
import WorkspaceIdentity from './WorkspaceIdentity'

/* eslint-disable max-lines-per-function */
export default function SidebarMenu({
  user,
  permissions,
  userNavigation,
  workspaceButtonReference,
  workspace,
  showSidebar,
  isOpen,
  handleIntercomClick,
  sidebarCollapsed,
  setSidebarCollapsed,
  setSidebarOpen,
  setWorkspacePanelOpen,
  hideOnFullScreen,
  organizationMenus
}) {
  return (
    <>
      <div className="relative flex items-center flex-shrink-0 px-6">
        <Logo className={classNames('transition-all', sidebarCollapsed ? 'opacity-0' : 'opacity-100')} />
        <button
          onClick={/* istanbul ignore next */ () => setSidebarCollapsed(!sidebarCollapsed)}
          className="absolute right-0 p-4 cursor-pointer max-lg:hidden"
          data-testid="sidebar-collapser"
        >
          {sidebarCollapsed ? (
            <Bars3Icon className="absolute top-0 w-8 h-8 text-background-500 right-6" />
          ) : (
            <ChevronLeftIcon className="absolute top-0 w-5 h-5 text-background-500 right-4" />
          )}
        </button>
        <button
          onClick={/* istanbul ignore next */ () => setSidebarOpen(false)}
          className="absolute right-0 p-4 cursor-pointer lg:hidden"
          data-testid="sidebar-collapser-mobile"
        >
          <ChevronLeftIcon className="absolute top-0 w-5 h-5 text-background-500 right-4" />
        </button>
      </div>
      <div className="flex flex-col flex-1 h-0 pt-2 mt-6 overflow-hidden">
        <ProfileDropdownMenu
          userName={`${user?.firstName} ${user?.lastName}`}
          userOrg={user?.defaultOrgName}
          sidebarCollapsed={sidebarCollapsed}
          userNavigation={userNavigation}
          userAvatar={user?.photoURL}
          userEmail={user?.email}
        />
        <div
          className={classNames(
            'transition-all z-12 duration-200 lg:inset-y-0 border-r lg:bg-gray-50',
            !hideOnFullScreen && (sidebarCollapsed ? 'lg:w-20' : 'lg:w-[360px]'),
            hideOnFullScreen ? 'hidden' : 'lg:fixed',
            [!user.emailVerified && 'notyet-verified mt-12']
          )}
        >
          <div className="flex h-full">
            {!sidebarCollapsed && (
              <div className="w-[80px] border-solid border-r border-gray-300 flex flex-col justify-start items-center">
                <button
                  onClick={/* istanbul ignore next */ () => setSidebarCollapsed(!sidebarCollapsed)}
                  className="p-6 cursor-pointer mb-4"
                  data-testid="sidebar-collapser"
                >
                  <ChevronDoubleLeftIcon className="w-6 h-6 text-background-500 right-4" />
                </button>
                {organizationMenus}
              </div>
            )}
            <div
              className={classNames(
                'lg:pt-5 lg:pb-4 w-full',
                hideOnFullScreen ? 'hidden' : 'lg:flex lg:flex-col flex-1'
              )}
            >
              <div className="relative flex items-center flex-shrink-0 px-6">
                <Logo className={classNames('transition-all', sidebarCollapsed ? 'opacity-0' : 'opacity-100')} />
                {sidebarCollapsed && (
                  <button
                    onClick={/* istanbul ignore next */ () => setSidebarCollapsed(false)}
                    className="absolute right-0 p-4 cursor-pointer"
                    data-testid="sidebar-collapser"
                  >
                    <Bars3Icon className="absolute top-0 w-8 h-8 text-background-500 right-6" />
                  </button>
                )}
              </div>
              <div
                className={classNames(
                  'transition-all overflow-hidden w-[50px] flex flex-col items-center justify-start mx-4',
                  sidebarCollapsed ? 'h-auto' : 'h-0',
                  sidebarCollapsed && 'mt-10'
                )}
              >
                {organizationMenus}
              </div>
              <div className={classNames('flex flex-col flex-1 h-0 pt-2 overflow-hidden', !sidebarCollapsed && 'mt-6')}>
                {user?.workspaces && !!user?.workspaces?.length && (
                  <div className="px-3">
                    <WorkspaceIdentity
                      buttonRef={workspaceButtonReference}
                      title={workspace?.getWorkspaceName?.(user.defaultWorkspaceId)}
                      subtitle="Workspace"
                      sidebarCollapsed={sidebarCollapsed}
                      useIndigoBackground
                      isWorkspaceSwitchable={user?.workspaces?.length > 1}
                      setWorkspacePanelOpen={setWorkspacePanelOpen}
                      onTitleFinishChange={() => workspace?.syncWorkspaceName?.(user.defaultWorkspaceId)}
                      onTitleChange={(value) => workspace?.changeWorkspaceName?.(user.defaultWorkspaceId, value)}
                      showSidebar={showSidebar}
                    />
                  </div>
                )}
                <SidebarNavigationDesktop
                  user={user}
                  permissions={permissions}
                  sidebarCollapsed={sidebarCollapsed}
                  onClick={() => setSidebarOpen(false)}
                  isOpen={isOpen}
                  handleIntercomClick={handleIntercomClick}
                />
              </div>
            </div>
          </div>
        </div>
        {user?.workspaces && !!user?.workspaces?.length && (
          <div className="mt-[60px] px-3">
            <WorkspaceIdentity
              buttonRef={workspaceButtonReference}
              title={workspace?.getWorkspaceName?.(user.defaultWorkspaceId)}
              subtitle="Workspace"
              sidebarCollapsed={sidebarCollapsed}
              useIndigoBackground
              isWorkspaceSwitchable={user?.workspaces?.length > 1}
              setWorkspacePanelOpen={setWorkspacePanelOpen}
              onTitleFinishChange={() => workspace?.syncWorkspaceName?.(user.defaultWorkspaceId)}
              onTitleChange={(value) => workspace?.changeWorkspaceName?.(user.defaultWorkspaceId, value)}
              showSidebar={showSidebar}
            />
          </div>
        )}
        <SidebarNavigationDesktop
          user={user}
          permissions={permissions}
          sidebarCollapsed={sidebarCollapsed}
          onClick={() => setSidebarOpen(false)}
          isOpen={isOpen}
          handleIntercomClick={handleIntercomClick}
        />
      </div>
    </>
  )
}

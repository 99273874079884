// istanbul ignore file
import { API_METHODS, API_SERVICES } from '../../utils/constants'
import useFetchAPI from '../useFetchAPI'
import PayloadNormalizer from '../../utils/payloadNormalizer'

const usePersona = () => {
  const [getAllPersona, isLoadingAllPersona, errorGettingAllPersona, allPersona, allPersonaUtils] = useFetchAPI(
    API_METHODS.GET_ALL,
    API_SERVICES.PERSONA
  )
  const [getAllJourney] = useFetchAPI(API_METHODS.GET_ALL, API_SERVICES.JOURNEY)
  const [getOnePersona, isLoadingOnePersona, errorGettingOnePersona, onePersona, onePersonaUtils] = useFetchAPI(
    API_METHODS.GET_ONE,
    API_SERVICES.PERSONA
  )
  const [createPersonaAPI, isCreatingPersona, errorCreatingPersona, createPersonaState, createPersonaUtils] =
    useFetchAPI(API_METHODS.CREATE, API_SERVICES.PERSONA)
  const [removePersona, isRemovingPersona, errorRemovingPersona] = useFetchAPI(API_METHODS.DELETE, API_SERVICES.PERSONA)
  const [updatePersonaAPI, isUpdatingPersona, errorUpdatingPersona, updatePersonaResult, updatePersonaUtils] =
    useFetchAPI(API_METHODS.UPDATE, API_SERVICES.PERSONA)

  const payloadNormalizer = new PayloadNormalizer()

  const createPersona = (payload, ...restArgument) => {
    const payloadNormalized = payloadNormalizer.persona(payload)
    return createPersonaAPI(payloadNormalized, ...restArgument)
  }

  const updatePersona = (id, payload, ...restArgument) => {
    const payloadNormalized = payloadNormalizer.persona(payload)
    return updatePersonaAPI(id, payloadNormalized, ...restArgument)
  }

  const addJourneysToPersonas = async (personas) => {
    return await Promise.all(
      personas?.map(async (persona) => {
        const id = persona._id
        const journeyPersona = await getAllJourney({
          where: [
            {
              field: 'personas._id',
              op: 'equal',
              val: id
            }
          ],
          projection: [
            '_id',
            'createdAt',
            'name',
            'mapUrl',
            'updatedAt',
            'totalPersonas',
            'totalProblems',
            'totalOpportunities'
          ]
        })
        persona.journeys = journeyPersona || []
      })
    )
  }

  const getAllByOrgId = async ({ orgId, ...rest }) => {
    const result = (await allPersonaUtils.getAllByOrgId({ orgId: orgId, ...rest })) || []

    return await addJourneysToPersonas(result)
  }

  return {
    getAll: {
      action: getAllPersona,
      isLoading: isLoadingAllPersona,
      error: errorGettingAllPersona,
      result: allPersona || [],
      utils: { ...allPersonaUtils, getAllByOrgId }
    },
    getOne: {
      action: getOnePersona,
      isLoading: isLoadingOnePersona,
      error: errorGettingOnePersona,
      result: onePersona,
      utils: onePersonaUtils
    },
    create: {
      action: createPersona,
      isLoading: isCreatingPersona,
      error: errorCreatingPersona,
      result: createPersonaState,
      utils: createPersonaUtils
    },
    remove: {
      action: removePersona,
      isLoading: isRemovingPersona,
      error: errorRemovingPersona
    },
    update: {
      action: updatePersona,
      isLoading: isUpdatingPersona,
      error: errorUpdatingPersona,
      utils: updatePersonaUtils
    }
  }
}

export default usePersona

import React from 'react'
import Avatar from '../ui/avatar/Avatar'
import getInitials from '../../utils/getInitials'
import classNames from '../../utils/text/classnames'
import TooltipComposer from '../ui/tooltip/TooltipComposer'

export const OrganizationButton = ({ organization, isActive, onOrganizationSelect }) => {
  const organizationName = organization.displayName || organization.display_name || organization.name

  return (
    <button
      key={organization.id}
      data-testid="organization-menu-button"
      onClick={() => onOrganizationSelect(organization.id)}
      className={classNames(
        'org-tooltip',
        'my-2 bg-transparent relative',
        isActive &&
          'after:content-[""] after:border-2 after:border-indigo-500 after:absolute after:-top-[4px] after:-left-[4px] after:-right-[4px] after:-bottom-[4px] after:rounded-[12px]'
      )}
      data-tooltip-content={organizationName}
    >
      <Avatar
        size="md"
        className={classNames('flex-shrink-0 workspace-identity-wrapper !rounded-lg relative')}
        initials={getInitials(organizationName)}
        useIndigoBackground
        imageUrl={organization.logo}
      />
    </button>
  )
}

export const OrganizationList = ({ organizations, activeOrganizationId, sidebarCollapsed, onOrganizationSelect }) => {
  const filteredOrganizations = sidebarCollapsed
    ? organizations.filter((org) => org.id === activeOrganizationId)
    : organizations

  return (
    <div className="mt-5 flex flex-col items-center justify-start">
      {filteredOrganizations.map((organization) => (
        <OrganizationButton
          key={organization.id}
          organization={organization}
          isActive={organization.id === activeOrganizationId}
          onOrganizationSelect={onOrganizationSelect}
        />
      ))}
    </div>
  )
}

export const TooltipWrapper = ({ children }) => {
  return (
    <>
      {children}
      <TooltipComposer
        selector=".org-tooltip"
        place="right"
        noArrow={true}
        customStyle={{ fontSize: '12px', padding: '8px' }}
        customClass="!rounded !bg-background-500 ml-1.5"
      />
    </>
  )
}

export const OrganizationMenu = ({ user, sidebarCollapsed, onOrganizationSelect }) => {
  return (
    <TooltipWrapper>
      <OrganizationList
        organizations={user?.organizations || []}
        activeOrganizationId={user?.defaultOrg}
        sidebarCollapsed={sidebarCollapsed}
        onOrganizationSelect={onOrganizationSelect}
      />
    </TooltipWrapper>
  )
}

/* eslint-disable max-lines-per-function */
import { useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import JwtDecode from 'jwt-decode'
import { CONFIG } from '../utils/constants'
import sanitizeObject from '../utils/sanitizeObject'
import { parsePermissions } from '../components/permissions/Can'
import DataMapper from '../utils/dataMapper'
import { logger } from '../utils/logger'
import { useLocalStorage } from './useLocalStorage'
import useGetToken from './useGetToken'

export default function useAuthProvider() {
  const { user, logout, loginWithRedirect } = useAuth0()
  const [token, setToken] = useState()
  const [permissions, setPermissions] = useState()
  const [userData, changeUserData] = useState()
  const userDataReference = useRef()

  // This is where we store the current active workspace
  const [activeWorkspaces, changeActiveWorkspaces, activeWorkspacesReference] = useLocalStorage('activeWorkspaces', {})

  const getAuth0Token = useGetToken()
  const tokenReference = useRef()

  const setUserData = (data) => {
    changeUserData(data)
    userDataReference.current = data
  }

  const signout = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })

  const fetchUserData = async (userId) => {
    const auth0Token = await getAuth0Token()
    const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/users/${userId}?notificationAuth=true&disableCache=true`, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth0Token}`
      }
    })
    const result = await response.json()

    if (!response.ok) {
      throw new Error(result.message)
    }
    return result
  }

  const updateUserData = async (userId, userData) => {
    const sanitizedPayload = sanitizeObject(userData)
    const response = await fetch(`${CONFIG.BACKEND_BASE_URL}/users/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(sanitizedPayload)
    })
    const result = await response.json()
    if (!response.ok) {
      throw new Error(result.message)
    }
    return result
  }

  const getToken = async () => {
    try {
      const auth0Token = await getAuth0Token()
      if (!auth0Token) return
      tokenReference.current = auth0Token
      setToken(auth0Token)

      const payload = JwtDecode(tokenReference.current)
      const userId = payload?.sub
      const userData = await fetchUserData(userId)
      const activeOrgId = payload.org_id

      const rawPermissions = userData?.permissions || payload?.permissions || []

      const activeWorkspaces = activeWorkspacesReference.current
      const workspaceId = activeWorkspaces[user.org_id] || null

      const workspacesBelongingToCurrentOrg =
        userData?.user_metadata?.workspaces?.filter((w) => w.organizationId === user.org_id) || []

      // Map each workspace individually
      const mappedWorkspaces = workspacesBelongingToCurrentOrg.map((w) => new DataMapper().workspace(w))

      const selectedWorkspace = mappedWorkspaces.find((w) => w.id === workspaceId) || mappedWorkspaces[0]

      // Update activeWorkspaces if workspaceId was not set
      if (!workspaceId && selectedWorkspace?.id) {
        changeActiveWorkspaces((previous) => ({
          ...previous,
          [user.org_id]: selectedWorkspace.id
        }))
      }

      const workspaceData = selectedWorkspace

      const workspacePermissions = rawPermissions?.filter((permission) => permission?.includes(workspaceData?.id))

      const parsedPermissions = parsePermissions(workspacePermissions)
      setPermissions(parsedPermissions)
      const finalUserData = formatUser({
        ...userData,
        activeOrgId
      })
      setUserData(finalUserData)
    } catch (error) {
      logger.error(error)
    }
  }

  useEffect(() => {
    if (!user?.sub) return
    getToken()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.sub])

  const getUserData = (userData) => {
    if (!userData) return null

    const currentActiveOrgIdInUserToken = user.org_id
    const activeWorkspaces = activeWorkspacesReference.current
    const workspaceId = activeWorkspaces[currentActiveOrgIdInUserToken] || null

    const orgWorkspaces = userData.workspaces
      .filter((w) => w.organizationId === currentActiveOrgIdInUserToken)
      .map((w) => new DataMapper().workspace(w))

    const selectedWorkspace = orgWorkspaces.find((w) => w.id === workspaceId) || orgWorkspaces[0]
    const workspaceData = selectedWorkspace

    const orgData = userData.organizations
      .filter((org) => org.id === currentActiveOrgIdInUserToken)
      .map((org) => new DataMapper().workspace(org))[0]

    return {
      ...userData,
      defaultOrg: user.org_id,
      defaultOrgName: orgData?.displayName,
      defaultWorkspaceId: workspaceData?.id,
      defaultWorkspaceName: workspaceData?.displayName
    }
  }

  const setActiveWorkspaceId = (id) => {
    changeActiveWorkspaces((previous) => ({
      ...previous,
      [user.org_id]: id
    }))

    const rawPermissions = userData?.metaData?.permissions
    const workspaceData = new DataMapper().workspace(userData?.metaData?.workspaces?.find((w) => w.id === id))
    const workspacePermissions = rawPermissions.filter((permission) => permission.includes(workspaceData.id))
    const parsedPermissions = parsePermissions(workspacePermissions)
    setPermissions(parsedPermissions)
  }

  return {
    user: getUserData(userData),
    getUserRefData: () => {
      const data = userDataReference.current
      return getUserData(data)
    },
    permissions,
    signout,
    token,
    refreshToken: getToken,
    updateUserData,
    setActiveWorkspaceId,
    activeWorkspaces,
    activeWorkspacesRef: activeWorkspacesReference,
    fetchUserData,
    loginWithRedirect
  }
}

function formatUser(user) {
  /* istanbul ignore next */
  if (!user) return

  const userMetadata = user?.user_metadata || {}

  const userEmail = user.email?.split('@')[0]
  const userOrganizations = user.organizations || userMetadata?.organizations || [{ id: user.activeOrgId }]
  const userWorkspaces = userMetadata?.workspaces || []
  const userOrgs = userOrganizations?.map((org) => org.id)

  // We have an individual test case for each conditional below, but for some reason the coverage tool is not picking it up
  const isOnboarded = (() => {
    if (!userMetadata?.firstName) {
      return false
    }

    /* istanbul ignore next */
    if (!userMetadata?.lastName) {
      return false
    }

    /* istanbul ignore next */
    if (!userMetadata?.role) {
      return false
    }
    return true
  })()

  const firstName = userMetadata?.firstName || user.given_name || userEmail
  const lastName = userMetadata?.lastName || user.family_name || userEmail
  const initial = `${firstName?.charAt(0)}${lastName?.charAt(0)}`?.toLowerCase()
  const updatedAvatarUrl = user.picture?.replace(/..\.png$/, `${initial}.png`)

  const decodedUrl = updatedAvatarUrl?.replaceAll('%3A', ':').replaceAll('%2F', '/')

  return {
    uid: user.user_id || user.email,
    organizations: userOrganizations,
    workspaces: userWorkspaces,
    orgIds: userOrgs,
    email: user.email,
    emailVerified: user.email_verified,
    firstName,
    lastName,
    role: userMetadata?.role,
    displayName: user.name || `${firstName} ${lastName}`,
    onboarded: isOnboarded,
    photoURL: decodedUrl,
    isAnonymous: user.isAnonymous,
    lastLoginAt: user.last_login || null,
    createdAt: user.created_at,
    updatedAt: user.updated_at,
    metaData: user.user_metadata,
    hmac: user.hmac
  }
}

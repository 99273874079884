import React from 'react'
import { FaceFrownIcon, LightBulbIcon } from '@heroicons/react/24/outline'
import {
  BoltIcon,
  BugAntIcon,
  CheckBadgeIcon,
  CircleStackIcon,
  FireIcon,
  HandThumbUpIcon,
  ShieldCheckIcon
} from '@heroicons/react/24/solid'
import ArticleIcon from '../components/icons/ArticleIcon'
import EmptyBoxIcon from '../components/icons/errors/EmptyBoxIcon'
import ComebackLaterIcon from '../components/icons/errors/ComebackLaterIcon'
import t from '../data/i18n'
import SessionTimeoutIcon from '../components/icons/errors/SessionTimeoutIcon'

export const CONFIG = {
  IS_PRODUCTION: process.env.REACT_APP_ENV_NAME === 'production',
  IS_LOCAL: process.env.REACT_APP_ENV_NAME === 'local',
  IS_CI: process.env.CI !== undefined,
  ENV_NAME: process.env.REACT_APP_ENV_NAME,
  BACKEND_BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL,
  SENTRY_DNS: process.env.REACT_APP_SENTRY_DNS,
  SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE
}
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{8,}$/
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE
export const STATSIG_CLIENT_SDK_KEY = process.env.REACT_APP_STATSIG_CLIENT_SDK_KEY
export const REACT_APP_NOVU_APP_ID = process.env.REACT_APP_NOVU_APP_ID
export const AG_GRID_LICENSE_KEY = process.env.REACT_APP_AG_GRID_LICENSE

export const API_SERVICES = {
  DASHBOARD: 'dashboards',
  JOURNEY: 'journeys',
  OPPORTUNITY: 'opportunities',
  ORGANIZATION: 'organizations',
  PERSONA: 'personas',
  PROBLEM: 'problems',
  WORKSPACE: 'workspaces',
  USER_WORKSPACES: 'user/workspaces',
  ISSUE: 'issues',
  COMMENT: 'comments'
}

export const API_METHODS = {
  GET_ALL: 'GET_ALL',
  GET_PAGINATED: 'GET_PAGINATED',
  GET_ALL_WITH_PAYLOAD: 'GET_ALL_WITH_PAYLOAD',
  GET_ONE: 'GET_ONE',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE'
}

export const DUPLICATE_OPTIONS = [
  { id: 1, name: 'External Links' },
  { id: 2, name: 'Attachments' }
]

export const IMPROVEMENT_TYPES = {
  PROBLEM: { name: 'Problem', value: 'problem' },
  OPPORTUNITY: { name: 'Opportunity', value: 'opportunity' }
}

export const CARD_TYPES = [
  { name: 'Pain Point', value: 'pain-point', icon: <FaceFrownIcon className="w-5 h-5 mr-2 text-red-500" /> },
  { name: 'Insight', value: 'insight', icon: <LightBulbIcon className="w-5 h-5 mr-2 text-yellow-500" /> },
  { name: 'Other', value: 'idea', icon: <ArticleIcon className="w-5 h-5 mr-2 text-marine-blue-500" /> }
]

export const CARD_STATUSES = [
  { name: 'Share', value: 'share' },
  { name: 'No Share', value: 'no-share' }
]

export const CARD_SORT = [
  { name: 'Last Created', value: 'last-created' },
  { name: 'Last Modified', value: 'last-modified' }
]

const BG_BLUE_100_COLOR = 'bg-blue-100'
const BG_GREEN_100_COLOR = 'bg-green-100'
const TEXT_BLUE_800_COLOR = 'text-blue-800'
const TEXT_GREEN_800_COLOR = 'text-green-800'

const IN_PROGRESS_TEXT = 'In Progress'

export const STATUS_TYPES = [
  { id: 1, value: 'open', name: 'Open', isDefault: true, bgColor: 'bg-gray-100', fontColor: 'text-gray-800' },
  { id: 2, value: 'in-progress', name: IN_PROGRESS_TEXT, bgColor: BG_BLUE_100_COLOR, fontColor: TEXT_BLUE_800_COLOR },
  { id: 3, value: 'in-review', name: 'In Review', bgColor: BG_BLUE_100_COLOR, fontColor: TEXT_BLUE_800_COLOR },
  { id: 4, value: 'closed', name: 'Closed', bgColor: BG_GREEN_100_COLOR, fontColor: TEXT_GREEN_800_COLOR },
  { id: 5, value: 'blocked', name: 'Blocked', bgColor: 'bg-red-100', fontColor: 'text-red-800' }
]

export const PRIORITY_TYPES = [
  { id: 0, value: 'none', name: 'None', isDefault: true, bgColor: 'bg-gray-100', fontColor: 'text-gray-800' },
  { id: 1, value: 'high', name: 'High', bgColor: 'bg-red-100', fontColor: 'text-red-800' },
  { id: 2, value: 'medium', name: 'Medium', bgColor: 'bg-yellow-100', fontColor: 'text-yellow-800' },
  { id: 3, value: 'low', name: 'Low', bgColor: BG_BLUE_100_COLOR, fontColor: TEXT_BLUE_800_COLOR }
]

export const ROADMAP_TYPES = [
  { id: 0, value: 'none', name: 'None', isDefault: true, bgColor: 'bg-gray-100', fontColor: 'text-gray-800' },
  { id: 1, value: 'now', name: 'Now', bgColor: BG_BLUE_100_COLOR, fontColor: TEXT_BLUE_800_COLOR },
  { id: 2, value: 'next', name: 'Next', bgColor: BG_BLUE_100_COLOR, fontColor: TEXT_BLUE_800_COLOR },
  { id: 3, value: 'later', name: 'Later', bgColor: BG_BLUE_100_COLOR, fontColor: TEXT_BLUE_800_COLOR }
]

export const MODE_TYPES = [
  { id: 1, value: 'online', name: 'Online', bgColor: BG_GREEN_100_COLOR, fontColor: TEXT_GREEN_800_COLOR },
  { id: 2, value: 'offline', name: 'Offline', bgColor: BG_BLUE_100_COLOR, fontColor: TEXT_BLUE_800_COLOR }
]

export const STATE_TYPES = [
  { id: 1, value: 'current', name: 'Current', bgColor: BG_GREEN_100_COLOR, fontColor: TEXT_GREEN_800_COLOR },
  { id: 2, value: 'future', name: 'Future', bgColor: BG_BLUE_100_COLOR, fontColor: TEXT_BLUE_800_COLOR }
]

export const EFFORT_TYPES = [
  { id: 1, value: 1, name: 'Very Low' },
  { id: 2, value: 2, name: 'Low' },
  { id: 3, value: 3, name: 'Medium' },
  { id: 4, value: 4, name: 'High' },
  { id: 5, value: 5, name: 'Very High' }
]

export const OLD_ISSUE_TYPES = [
  {
    id: 'task',
    value: 'task',
    name: 'Task',
    icon: <CheckBadgeIcon className="w-5 h-5 mr-2 text-blue-500" />,
    iconOnly: <CheckBadgeIcon className="w-5 h-5 text-blue-500" />,
    iconColor: 'text-blue-500'
  },
  {
    id: 'problem',
    value: 'problem',
    name: 'Problem',
    icon: <FireIcon className="w-5 h-5 mr-2 text-red-500" />,
    iconOnly: <FireIcon className="w-5 h-5 text-red-500" />,
    iconColor: 'text-red-500'
  },
  {
    id: 'opportunity',
    value: 'opportunity',
    name: 'Opportunity',
    icon: <HandThumbUpIcon className="w-5 h-5 mr-2 text-green-500" />,
    iconOnly: <HandThumbUpIcon className="w-5 h-5 text-green-500" />,
    iconColor: 'text-green-500'
  },
  {
    id: 'idea',
    value: 'idea',
    name: 'Idea',
    icon: <BoltIcon className="w-5 h-5 mr-2 text-yellow-500" />,
    iconOnly: <BoltIcon className="w-5 h-5 text-yellow-500" />,
    iconColor: 'text-yellow-500'
  },
  {
    id: 'dataPoint',
    value: 'dataPoint',
    name: 'Data Point',
    icon: <CircleStackIcon className="w-5 h-5 mr-2 text-cyan-500" />,
    iconOnly: <CircleStackIcon className="w-5 h-5 text-cyan-500" />,
    iconColor: 'text-cyan-500'
  }
]

export const NEW_ISSUE_TYPES = [
  OLD_ISSUE_TYPES[0],
  {
    id: 'bug',
    value: 'bug',
    name: 'Bug',
    icon: <BugAntIcon className="w-5 h-5 mr-2 text-pink-500" />,
    iconOnly: <BugAntIcon className="w-5 h-5 text-pink-500" />,
    iconColor: 'text-pink-500'
  },
  {
    id: 'risk',
    value: 'risk',
    name: 'Risk',
    icon: <FireIcon className="w-5 h-5 mr-2 text-orange-500" />,
    iconOnly: <FireIcon className="w-5 h-5 text-orange-500" />,
    iconColor: 'text-orange-500'
  },
  OLD_ISSUE_TYPES[2],
  OLD_ISSUE_TYPES[4],
  {
    id: 'decision',
    value: 'decision',
    name: 'Decision',
    icon: <ShieldCheckIcon className="w-5 h-5 mr-2 text-purple-500" />,
    iconOnly: <ShieldCheckIcon className="w-5 h-5 text-purple-500" />,
    iconColor: 'text-purple-500'
  }
]

export const ISSUE_ORDER_BY_STATUS = ['Blocked', 'Open', IN_PROGRESS_TEXT, 'In Review', 'Closed']

export const DUE_DATE = [
  { name: 'All', type: 'clear', value: 'clear' },
  { name: 'Overdue', value: 'overdue' },
  { name: 'Not Overdue', value: 'not-overdue' }
]

export const STEP_STATUS = {
  NOT_STARTED: {
    id: 1,
    name: 'Not Started',
    style: { rounded: 'full', size: 'md', customColor: 'bg-gray-400 text-white' }
  },
  IN_PROGRESS: {
    id: 2,
    name: IN_PROGRESS_TEXT,
    style: { rounded: 'full', size: 'md', customColor: 'bg-blue-500 text-white' }
  },
  BLOCKED: {
    id: 3,
    name: 'Blocked',
    style: { rounded: 'full', size: 'md', customColor: 'bg-red-500 text-white' }
  },
  COMPLETED: {
    id: 4,
    name: 'Completed',
    style: { rounded: 'full', size: 'md', customColor: 'bg-green-500 text-white' }
  },
  BUG: {
    id: 5,
    name: 'Bug',
    style: { rounded: 'full', size: 'md', customColor: 'bg-red-500 text-white' }
  }
}

export const PERSONA_EXCLUDED_FIELDS = ['journeys', 'problems', 'opportunities']
export const PROBLEM_EXCLUDED_FIELDS = ['journeys']
export const OPPORTUNITY_EXCLUDED_FIELDS = ['journeys', 'personas']
export const ISSUE_EXCLUDED_FIELDS = ['journeys']
export const JOURNEY_EXCLUDED_FIELDS = ['problems', 'personas', 'opportunities', 'phases']

const RESOURCE_WRITE = 'resource:write'
const RESOURCE_READ = 'resource:read'
const RESOURCE_DELETE = 'resource:delete'

export const ACTIONS = {
  CREATE_JOURNEY: RESOURCE_WRITE,
  READ_JOURNEY: RESOURCE_READ,
  UPDATE_JOURNEY: RESOURCE_WRITE,
  DELETE_JOURNEY: RESOURCE_DELETE,
  CREATE_PERSONA: RESOURCE_WRITE,
  READ_PERSONA: RESOURCE_READ,
  UPDATE_PERSONA: RESOURCE_WRITE,
  DELETE_PERSONA: RESOURCE_DELETE,
  CREATE_PROBLEM: RESOURCE_WRITE,
  READ_PROBLEM: RESOURCE_READ,
  UPDATE_PROBLEM: RESOURCE_WRITE,
  DELETE_PROBLEM: RESOURCE_DELETE,
  CREATE_OPPORTUNITY: RESOURCE_WRITE,
  READ_OPPORTUNITY: RESOURCE_READ,
  UPDATE_OPPORTUNITY: RESOURCE_WRITE,
  DELETE_OPPORTUNITY: RESOURCE_DELETE,
  CREATE_ISSUE: RESOURCE_WRITE,
  READ_ISSUE: RESOURCE_READ,
  UPDATE_ISSUE: RESOURCE_WRITE,
  DELETE_ISSUE: RESOURCE_DELETE,
  CREATE_WORKSPACE: 'workspaces:write',
  READ_WORKSPACE: 'workspaces:read',
  UPDATE_WORKSPACE: 'workspaces:write',
  MANAGE_WORKSPACE: 'workspaces:manage',
  DELETE_WORKSPACE: 'workspaces:delete',
  CREATE_ORGANIZATION: 'organizations:write'
}

export const RoleTypes = [
  {
    id: 1,
    name: 'Admin',
    role: 'admin'
  },
  {
    id: 2,
    name: 'Editor',
    role: 'editor'
  },
  {
    id: 3,
    name: 'Viewer',
    role: 'viewer'
  }
]

export const STAGE_COLOR_1 = { colorName: 'purple', baseShade: 500, hex: '#8B5CF6' }
export const STAGE_COLOR_2 = { colorName: 'cyan', baseShade: 500, hex: '#06B6D4' }
export const STAGE_COLOR_3 = { colorName: 'yellow', baseShade: 400, hex: '#FBBF24' }

export const STAGE_COLORS = [STAGE_COLOR_1]

export const STAGE_COLORS_HEX = STAGE_COLORS.map((color) => color.hex)

export const FEATURE_FLAGS = {
  RICH_TEXT_EDITOR: 'rich_text_editor_available',
  GENERIC_ISSUE: 'generic_issues',
  NOTIFICATION: 'notification_available',
  COMMENT_SECTION: 'comment_section',
  STEP_LINKING_DISABLED: 'step_linking_disabled',
  NEW_ISSUE_TYPES: 'new_issue_types',
  AUDIT_LOGS: 'audit_logs',
  SHOW_ADD_BRANCH: 'show_add_branch'
}

export const ISSUE_STATUS = {
  OPEN: 'Open',
  IN_PROGRESS: IN_PROGRESS_TEXT,
  IN_REVIEW: 'In Review',
  CLOSED: 'Closed',
  BLOCKED: 'Blocked'
}

export const JOURNEY_TYPES = {
  PROJECT_JOURNEY: 'map.types.project',
  EXPERIENCE_JOURNEY: 'map.types.experience',
  PROCESS_JOURNEY: 'map.types.process',
  OTHER_JOURNEY: 'map.types.other'
}

export const imagesAllowedTypes = ['image/*', '.jpg', '.jpeg', '.png']
export const documentAllowedTypes = [
  ...imagesAllowedTypes,
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.txt',
  '.ppt',
  '.pptx',
  '.csv'
]

export const documentMaxSize = 1_048_500 * 10 // 10MB

export const documentThumbnailBgColor = {
  pdf: '#D15B50',
  doc: '#33528F',
  docx: '#33528F',
  xls: '#3A6F49',
  xlsx: '#3A6F49',
  txt: '#333333'
}

export const ISSUE_STATUS_PREVIEW = [
  { id: 'open', value: 'open', name: 'Open', fontColor: 'text-gray-700' },
  { id: 'inProgress', value: 'inProgress', name: IN_PROGRESS_TEXT, fontColor: 'text-blue-700' },
  { id: 'inReview', value: 'inReview', name: 'In Review', fontColor: 'text-blue-700' },
  { id: 'closed', value: 'closed', name: 'Closed', fontColor: 'text-emerald-700' },
  { id: 'blocked', value: 'blocked', name: 'Blocked', fontColor: 'text-red-700' }
]

export const ERROR_MESSAGES = {
  NOT_FOUND: {
    message: t('errors.texts.notFound.message'),
    details: t('errors.texts.notFound.messageDetails'),
    buttonLabel: t('errors.actions.navigateToHome'),
    icon: EmptyBoxIcon
  },
  SERVICE_UNAVAILABLE: {
    message: t('errors.texts.serviceUnavailable.message'),
    details: t('errors.texts.serviceUnavailable.messageDetails'),
    buttonLabel: t('errors.actions.confirm'),
    icon: ComebackLaterIcon
  },
  SESSION_TIMEOUT: {
    message: t('errors.texts.sessionTimeout.message'),
    details: t('errors.texts.sessionTimeout.messageDetails'),
    buttonLabel: t('errors.actions.login'),
    icon: SessionTimeoutIcon
  }
}

export const VIRTUALIZED_CONTAINER_HEIGHT = 45.66
export const DEFAULT_TEXT_WIDTH = 450
export const VIEW_ISSUE_DETAILS = 'view-issue-details'

export const SUBGOAL_STATUS = [
  {
    id: 1,
    value: 'not-started',
    name: 'Not Started',
    isDefault: true,
    bgColor: 'bg-pale-ash-400',
    fontColor: 'text-gray-800'
  },
  { id: 2, value: 'in-progress', name: 'In Progress', bgColor: 'bg-blue-500', fontColor: 'text-blue-800' },
  { id: 4, value: 'completed', name: 'Completed', bgColor: 'bg-green-600', fontColor: 'text-green-800' },
  { id: 5, value: 'blocked', name: 'Blocked', bgColor: 'bg-red-500', fontColor: 'text-red-800' }
]

export const ISSUES_VISIBILITY_LOCALSTORAGE_KEY = 'triniti-is-issue-hidden'

import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { logger } from '../../utils/logger'
import { PersonaContext } from '../../context/PersonaProvider'
import { ProblemContext } from '../../context/ProblemProvider'
import { OpportunityContext } from '../../context/OpportunityProvider'
import { JourneyContext } from '../../context/JourneyProvider'
import { IssueContext } from '../../context/IssueProvider.js'
import { WorkspaceContext } from '../../context/WorkspaceProvider'

// eslint-disable-next-line max-lines-per-function
function useWorkspaceSidebar({ onClose, setActiveWorkspaceId, getAllWorkspace, refreshToken, getUserRefData }) {
  const [isWorkspaceModalVisible, setWorkspaceModalVisible] = useState(false)
  const navigate = useNavigate()
  const [activeWorkspace, setActiveWorkspace] = useState('')

  const { getAll: getAllPersona } = useContext(PersonaContext)
  const { getAll: getAllProblem } = useContext(ProblemContext)
  const { getAll: getAllOpportunity } = useContext(OpportunityContext)
  const { getAll: getAllJourney } = useContext(JourneyContext)
  const { getAll: getAllIssue } = useContext(IssueContext)
  const { getAllMembersData } = useContext(WorkspaceContext)

  const fetchingData = () => {
    const user = getUserRefData()
    const workspaceId = user?.defaultWorkspaceId
    if (!workspaceId) {
      logger.info("User doesn't have a default workspace")
      return
    }
    getAllPersona.utils.getAllByOrgId({
      orgId: user?.defaultOrg,
      projection: ['_id', 'color', 'avatarUrl', 'displayName', 'role', 'totalProblems', 'totalJourneys']
    })
    getAllProblem.utils.getAllByOrgId({ orgId: user?.defaultOrg, projection: ['_id', 'name'] })
    getAllOpportunity.utils.getAllByOrgId({ orgId: user?.defaultOrg, projection: ['_id', 'name'] })
    getAllJourney.utils.getAllByOrgId({
      orgId: user?.defaultOrg,
      projection: [
        '_id',
        'createdAt',
        'name',
        'mapUrl',
        'updatedAt',
        'totalPersonas',
        'totalProblems',
        'totalOpportunities',
        'issues',
        'phases'
      ]
    })
    getAllIssue.utils.getAllByOrgId({
      orgId: user?.defaultOrg,
      projection: ['_id', 'name', 'type', 'updatedAt'],
      limit: 800,
      disableCache: true
    })
  }

  const onWorkspaceModalClose = (workspaceId) => {
    setWorkspaceModalVisible(false)
    if (workspaceId) {
      setActiveWorkspaceId(workspaceId)
      navigate('/home')
      setActiveWorkspace(workspaceId)
    }
  }

  const onWorkspaceModalShow = () => {
    setWorkspaceModalVisible(true)
  }

  const handleWorkspaceSelect = (id) => {
    setActiveWorkspaceId(id)
    onClose()
    navigate('/home')
    setActiveWorkspace(id)
    getAllMembersData({ workspaceId: id })
  }

  const refreshData = async () => {
    await refreshToken()
    getAllWorkspace()
  }

  return {
    isWorkspaceModalVisible,
    activeWorkspace,
    setWorkspaceModalVisible,
    onWorkspaceModalClose,
    onWorkspaceModalShow,
    handleWorkspaceSelect,
    refreshData,
    fetchingData
  }
}

export default useWorkspaceSidebar
